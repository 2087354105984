import slick from 'slick-carousel'
import $ from 'jquery'
import { favoriteManager } from './FavoriteManager.es6'

window.addEventListener('load', () => {

  const sortButtons = document.querySelectorAll('.js-sort')
  const sortArea = document.querySelector('#sortArea')
  if (sortArea) {
    const sortAreaChildren = sortArea.childNodes
    const tagId = location.search.split('?sort=')[1]
    if (tagId) {
      assort(tagId)

      sortButtons.forEach((sortButton) => {
        if (tagId !== sortButton.dataset.target) return
        sortButton.classList.add('active')
      })

    }

    sortButtons.forEach((sortButton) => {
      sortButton.addEventListener('click', (e) => {
        // e.preventDefault()
        const tag = sortButton.dataset.target
        sortButtons.forEach((child) => {
          if (!child.className) return
          child.classList.remove('active')
        })
        sortButton.classList.add('active')
        assort(tag)
      })
    })

    function assort(tag) {
      sortAreaChildren.forEach((child) => {
        if (!child.className) return
        child.classList.remove('is_show')

        if (tag === 'all') child.classList.add('is_show')
        if (child.dataset.tags.match(tag)) {
          child.classList.add('is_show')
        }
      })
    }
  }

  // snsボタンにURLをセットする
  const url = location.href
  const snsButtons = document.getElementById('snsButtons')
  if (snsButtons) {
    snsButtons.childNodes.forEach((child) => {
      if (!child.className) return
      child.childNodes.forEach((aLink) => {
        if (!aLink.className) return
        aLink.setAttribute('href', aLink.getAttribute('href').replace('{url}', url))
      })
    })
  }

  // 画面幅が1120の以下はheaderがfixしているのでスクロールできるようにする
  const windowWidth = window.innerWidth
  const headerElement = document.querySelector('.cmn-header--box')

  document.addEventListener('scroll', (e) => {
    const scrollY = document.documentElement.scrollLeft

    if (windowWidth < 1120 && windowWidth > 425) {
      headerElement.style.left = -scrollY + 'px'
    } else {
      headerElement.style.left = 0 + 'px'
    }

  })

})

$(function () {
  'use strict'

  function translateAnotherLanguage(langButton, translateButtons) {
    switch (langButton.dataset.lang) {
      case "ja":
        // setTimeout(() => {
        location.reload()
        // }, 100)
        translateButtons[0].click()
        // localStorage.setItem('lang', "/ja/ja")
        break;
      case "en":
        translateButtons[1].click()
        // localStorage.setItem('lang', "/ja/en")
        break;
      case "zh-CN":
        translateButtons[3].click()
        // localStorage.setItem('lang', "/ja/zh-CN")
        break;
      case "zh-TW":
        translateButtons[4].click()
        // localStorage.setItem('lang', "/ja/zh-TW")
        break;
      case "ko":
        translateButtons[2].click()
        // localStorage.setItem('lang', "/ja/ko")
        break;
    }
  }

  const langButtons = document.querySelectorAll('.cmn-splangmenu--link')
  const langButtonsOnOther = document.querySelectorAll('.cmn-spmenu--lang_link')
  // if (localStorage.getItem('lang')) {
  // setTimeout(() => {
  // document.cookie = `googtrans=${localStorage.getItem('lang')};`
  // }, )
  // }

  langButtons.forEach((langButton) => {
    langButton.addEventListener('click', () => {
      const iframes = document.querySelectorAll('iframe.skiptranslate')
      console.log(iframes)
      if (iframes.length !== 1) {
        const iframe = iframes[2]
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document
        const translateButtons = iframeDocument.querySelectorAll("a")
        translateAnotherLanguage(langButton, translateButtons)
      }
      if (iframes.length === 1) {
        const iframe = iframes[0]
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document
        const translateButtons = iframeDocument.querySelectorAll("a")
        translateAnotherLanguage(langButton, translateButtons)
      }

    })
  })

  langButtonsOnOther.forEach((langButton) => {
    langButton.addEventListener('click', () => {
      const iframes = document.querySelectorAll('iframe.skiptranslate')
      if (iframes.length !== 1) {
        const iframe = iframes[2]
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document
        const translateButtons = iframeDocument.querySelectorAll("a")
        translateAnotherLanguage(langButton, translateButtons)
      }
      if (iframes.length === 1) {
        const iframe = iframes[0]
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document
        const translateButtons = iframeDocument.querySelectorAll("a")
        translateAnotherLanguage(langButton, translateButtons)
      }
    })
  })






  var ww = $(window).width()

  var ua = window.navigator.userAgent.toLowerCase()
  var isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document

  /*------
  ヘッダーメニュー
  ------*/
  function headerScrollOn() {  // ヘッダーメニューがスクロールする場合
    $('.cmn-header').css({ 'overflow-y': 'scroll', height: '100%' })
    if (!isiOS) {
      $('body').css({ 'overflow': 'hidden' })
    }
  }

  function headerScrollOff() {  // ヘッダーメニューがスクロールしない場合
    $('.cmn-header').css({ 'overflow-y': '', height: '' })
    if (!isiOS) {
      $('body').css({ 'overflow': '' })
    }
  }

  // ヘッダーメニューボタン押下時
  $('.cmn-header--icon_link').on('click', function (e) {
    e.preventDefault()

    if (!$(this).hasClass('is_show')) {
      //メニューオープン

      // リセット
      $('.cmn-header--icon_link').removeClass('is_show')
      $('.cmn-slidemenu').hide()

      $('body').addClass('is_show-headermenu')
      $(this).addClass('is_show')

      var iconLinkClassLength = $(this).attr('class').split(' ')[0].length
      var iconLinkCurrentClass = $(this).attr('class').split(' ')[1].substr(iconLinkClassLength)
      var slidemenuClass = '.cmn-slidemenu' + iconLinkCurrentClass

      $(slidemenuClass).fadeToggle()

      // Info・Search・Menuボタン
      if (!$(this).hasClass('cmn-header--icon_link-lang')) {
        headerScrollOn()
      } else {
        // langボタン
        headerScrollOff()
      }
    } else {
      // ヘッダーのcloseボタン押下時
      $('.cmn-header').scrollTop(0)

      headerScrollOff()
      $('body').removeClass('is_show-headermenu')
      $(this).removeClass('is_show')
      $('.cmn-slidemenu').fadeOut()
    }
  })

  // メニューオープン時の画面下のcloseボタン押下時
  $('.cmn-slidemenu--close_btn a').on('click', function (e) {
    e.preventDefault()

    $('.cmn-header').scrollTop(0)

    headerScrollOff()
    $('body').removeClass('is_show-headermenu')
    $('.cmn-header--icon_link').removeClass('is_show')
    $('.cmn-slidemenu').fadeOut()
  })

  /*------
  pagetopボタン
  ------*/
  if (ww > 425) {
    var topBtn = $('#pagetop')
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 80) {
        //---- 画面を80pxスクロールしたら、ボタンを表示する
        topBtn.addClass('js-show')
      } else {
        //---- 画面が80pxより上なら、ボタンを表示しない
        topBtn.removeClass('js-show')
      }
    })
  }

  $('#pagetop').on('click', function (e) {
    e.preventDefault()
    $('body,html').animate({
      scrollTop: 0
    }, 500)
  })

  /*------
  TOP ヒーローイメージ スライダー
  ------*/
  if (ww > 425) {
    var swipeHeroSlide = false
  } else {
    var swipeHeroSlide = true
  }

  $('.m-hero--slide').slick({
    autoplay: true,
    swipe: swipeHeroSlide,
    pauseOnFocus: true,
    pauseOnHover: true,
    centerMode: true,
    variableWidth: true,
    slidesToScroll: 1,
    speed: 1000,
    dots: true,
  })

  /*------
  カテゴリー選択
  ------*/
  $('.m-menu--category_list:not(.no_narrowing)').children('li').on('click', function (e) {
    e.preventDefault()
    $(this).toggleClass('active')
  })

  $('.m-menu--category_list:not(.no_narrowing) .m-menu--category_link').on('mouseout', function () {
    $(this).blur()
  })

  /*------
  TOPICS スライダー（PCのみ）
  ------*/
  var topicsSlides = $('.m-topics--article_list-slider .m-topics--article_item').length

  if (ww > 425 && topicsSlides > 3) {
    $('.m-topics--article_list-slider').slick({
      autoplay: false,
      slidesToScroll: 1,
      pauseOnFocus: true,
      pauseOnHover: true,
      variableWidth: true,
      slidesToShow: 3,
      infinite: false,
      swipe: false,
    })
  }

  /*------
  PICKUP スライダー
  ------*/
  var pickupSlides = $('.m-pickup--item').length
  var slidesWidth = $('.m-pickup--img').outerWidth(true)

  // 枚数がwindow幅を超える場合、スライダー
  if (ww < slidesWidth * pickupSlides) {
    $('.m-pickup--list').slick({
      autoplay: true,
      autoplaySpeed: 0,
      speed: 8000,
      cssEase: 'linear',
      arrows: false,
      swipe: false,
      pauseOnFocus: false,
      pauseOnHover: false,
      variableWidth: true,
    })
  }

  /*------
  footer info スライダー
  ------*/
  $('.cmn-footer--info_list').slick({
    autoplay: true,
    slidesToScroll: 1,
    swipe: false,
    pauseOnFocus: true,
    pauseOnHover: true,
    variableWidth: true,
  })

  /*------
  レストランLP キーワードタグ
  ------*/
  $('.m-shoplist--result_keylink').on('click', function (e) {
    e.preventDefault()
    $(this).parent('.m-shoplist--result_keyitem').remove()
  })

  /*------
  ショップ詳細 写真サムネイル
  ------*/
  $('.m-shopphoto--thumbnail_link').on('click', function (e) {
    e.preventDefault()
    var photoSrc = $(this).find('.m-shopphoto--img-s').attr('src')
    $('.m-shopphoto--img-l').attr('src', photoSrc)
  })

  /*------
  お気に入りボタン オン・オフ
  ------*/
  // $('.m-favorite--icon').on('click', function (e) {
  //   e.preventDefault()
  //   $(this).toggleClass('active')
  // })

  /*------
  ピクト案内 開閉
  ------*/
  $('.m-map--picto_link').on('click', function (e) {
    e.preventDefault()
    $('.m-map--picto_description').slideToggle()
  })

  /*------
  SHOP NEWS スライダー（PCのみ） ※トップページのぞく
  ------*/
  var shopNewsSlides = $('.m-shopnews--slider_box .m-shopnews--article_item').length

  if (ww > 425 && shopNewsSlides > 4) {
    $('.m-shopnews--slider_box .m-shopnews--article_list').slick({
      autoplay: false,
      slidesToScroll: 1,
      pauseOnFocus: true,
      pauseOnHover: true,
      variableWidth: true,
      slidesToShow: 4,
      infinite: false,
      swipe: false,
    })
  }

  /*------
  インフォメーション 開閉
  ------*/
  $('.info-facility--description').hide()

  $('.info-facility--link').on('click', function (e) {
    e.preventDefault()
    $(this).parents('.info-facility--group').find('.info-facility--description').slideToggle()
  })

  /*------
  ID指定リンク ※ヘッダーメニューオープン時のページ内リンク（/information/）は別設定
  ------*/
  // ページ遷移時
  var url = $(location).attr('href')
  if (url.indexOf('#') != -1) {
    var anchor = url.split('#'),
      target = $('#' + anchor[anchor.length - 1]),
      headerHeight = $('.cmn-header--container').height(),
      pos = Math.floor(target.offset().top) - headerHeight
    $('html, body').animate({ scrollTop: pos }, 500)
  }

  function internalLink() {
    var anchor = $(this).attr('href').split('#'),
      target = $('#' + anchor[anchor.length - 1]),
      headerHeight = $('.cmn-header--container').height(),
      pos = Math.floor(target.offset().top) - headerHeight

    $('html, body').animate({ scrollTop: pos }, 500)
  }

  // ページ内リンク
  $('a[href^="#"]').on('click', function () {
    internalLink.call(this)
  })

  // メニューオープンしてid指定リンクを押下時、ページ内のリンクだったら、メニュークローズ
  $('.cmn-slidemenu--box a').on('click', function () {
    internalLink.call(this)

    $('.cmn-header').scrollTop(0)

    headerScrollOff()
    $('body').removeClass('is_show-headermenu')
    $('.cmn-header--icon_link').removeClass('is_show')
    $('.cmn-slidemenu').fadeOut()
  })

  /*------
  外部リンクアナウンス用モーダル
  ------*/
  //外部リンク押下時
  $('.caution_open').on('click', function (e) {
    e.preventDefault()
    var externalHref = $(this).attr('href')

    $('body').append('<div id="modal"></div>')
    $('#modal').load('/common/include/modal.inc', function () {
      $('.cmn-modal--explain_link').text(externalHref).attr('href', externalHref)
      $('.cmn-modal').fadeIn()
    })
  })

  //外部リンクアナウンス用モーダル　閉じるボタン押下時
  $(document).on('click', '.cmn-modal--btn', function (e) {
    e.preventDefault()
    $('.cmn-modal').fadeOut().delay(50).queue(function () {
      $('#modal').remove()
    })
  })

})

