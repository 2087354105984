class FavoriteManager {
  constructor () {
    this.favorites = []

    if(!localStorage.getItem('favorites')) {
      localStorage.setItem('favorites', this.favorites)
    } else {
      const favoritesInLocalStorage = localStorage.getItem('favorites').split(',')

      favoritesInLocalStorage.forEach((favorite) => {
        this.favorites.push(favorite)
      })
    }

  }

  add (tenantId) {
    this.favorites.push(tenantId)
    localStorage.setItem('favorites', this.favorites)
  }

  remove (tenantId) {
    this.favorites = this.favorites.filter((tenantIdInFavorite) => {
      return tenantIdInFavorite !== tenantId
    })
    localStorage.setItem('favorites', this.favorites)
  }

  get () {
    return this.favorites
  }

  /**
   * テナントIDがfavoritesに含まれいるかどうか返す
   */
  isActiveWithTenantId (tenantId) {
    const isActive = this.favorites.find((tenantIdInFavorites) => {
      return tenantIdInFavorites === tenantId
    })
    return !!(isActive !== undefined || null)
  }
}

export const favoriteManager = new FavoriteManager()
